<template>
    <div class="embed embed--video">
        <iframe
            v-if="embedUrl"
            :src="embedUrl"
            loading="lazy"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        />
    </div>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';

const props = defineProps<BaseEmbedProps>();

const embedUrl = computed(() => {
    if (props.url.includes('v=')) {
        const ytEmbed = props.url.split('v=')[1];
        // Strip any other query params.
        return 'https://www.youtube-nocookie.com/embed/' + ytEmbed.split('&')[0];
    } else if (props.url.startsWith('https://youtu.be/')) {
        return props.url.replace('https://youtu.be/', 'https://www.youtube-nocookie.com/embed/');
    }
    return null;
});

</script>

<template>
    <transition name="fade">
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="embedHtml"
            class="embed embed-issuu"
            v-html="embedHtml"
        />
        <!-- eslint-enable vue/no-v-html -->
        <content-placeholders
            v-else
            :rounded="true"
        >
            <content-placeholders-img style="height: 650px;" />
        </content-placeholders>
    </transition>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';
import Bugsnag from '@bugsnag/js';

const props = defineProps<BaseEmbedProps>();

const embedHtml = ref<string | null>(null);

watch(() => props.url, async(value) => {
    try {
        // const issuuClient = $axios.create({})

        // Todo add a issuue test doc to the /who-we-are
        const url = `https://issuu.com/oembed?url=${encodeURI(value)}&iframe=true&format=json`;
        const response = await $fetch(url, {
            method: 'GET',
            // headers: {
            //     common: {
            //         Accept: 'text/json',
            //     }
            // }
        }) as any;

        console.log(response);
        console.log(url);
        // delete issuuClient.defaults.headers.common.Authorization;

        embedHtml.value = response?.data?.html;
    } catch (e: any) {
        Bugsnag.notify(e);
        // eslint-disable-next-line no-console
        console.error(e);

        embedHtml.value = '';
    }
});

</script>

<template>
    <div
        :id="tableauId"
        class="tableauPlaceholder"
        style="position: relative;"
    >
        <noscript>
            <a :href="url">
            <img
            :alt="description"
            :src="staticImage"
            style="border: none;"
            />
            </a>
        </noscript>
        <object
            class="tableauViz"
            style="display: none;"
        >
            <param
                v-for="(value, key) in params"
                :key="key"
                :name="key"
                :value="value"
            >
        </object>
    </div>
</template>
<script setup lang="ts">
import {onMounted, computed} from 'vue';

const props = defineProps<{
    code: string
    url?: string;
    description?: string;
}>();


const decodeValue = (encodedUrl: string) => encodedUrl ? decodeURIComponent(encodedUrl)?.replaceAll('&#47;', '/') : '';

const extractStaticImageFromCode = (code: string) => {
    const match = code.match(/<noscript><a[^>]*><img[^>]*src=['"](.*?)['"]/);
    return match ? match[1] : '';
};

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const staticImage = computed(() => decodeValue(extractStaticImageFromCode(props.code)));

const params = computed(() => {
    const regex = /<param name=['"](.*?)['"] value=['"](.*?)['"]/g;
    const matches = props.code.match(regex);
    return matches?.reduce((acc, match) => {
        // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
        const [_, name, value] = match.match(/<param name=['"](.*?)['"] value=['"](.*?)['"]/);
        return {
            ...acc,
            [name]: decodeValue(value),
        };
    }, {});
});

const tableauId = 'viz' + new Date().getTime();

onMounted(() => {
    const divElement = document.getElementById(tableauId);
    const vizElement = divElement?.getElementsByTagName('object')[0];

    if (divElement?.offsetWidth) {
        if (divElement.offsetWidth > 800) {
            vizElement.style.width = '100%';
            vizElement.style.height = (divElement.offsetWidth * 0.75) + 'px';
        } else if (divElement.offsetWidth > 500) {
            vizElement.style.width = '100%';
            vizElement.style.height = (divElement.offsetWidth * 0.75) + 'px';
        } else {
            vizElement.style.width = '100%';
            vizElement.style.height = '1127px';
        }

        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
        vizElement?.parentNode?.insertBefore(scriptElement, vizElement);
    }
});
</script>

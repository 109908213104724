<template>
    <div class="embed">
        <transition name="fade">
            <iframe
                v-if="embedUrl"
                :src="embedUrl"
                loading="lazy"
                width="100%"
                height="200"
                frameborder="0"
                scrolling="no"
            />
            <content-placeholders
                v-else
                :rounded="true"
            >
                <content-placeholders-img style="height: 200px;" />
            </content-placeholders>
        </transition>
    </div>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';

const props = defineProps<BaseEmbedProps>();
const embedUrl = computed(() => {
    if (props.url.includes('buzzsprout.com/')) {
        const splitUrl = props.url.split('buzzsprout.com')?.[1];
        const playerId = splitUrl?.match('(?!/)([0-9]+)(?=-)')?.[0];
        if (!splitUrl || !playerId) {
            return null;
        }
        return `https://www.buzzsprout.com/${splitUrl}?client_source=small_player&amp;iframe=true&amp;referrer=https://www.buzzsprout.com/${splitUrl}.js?container_id=buzzsprout-player-${playerId}&amp;player=small`;
    }
    return null;
});

</script>

<template>
    <div class="embed embed--video">
        <iframe
            v-if="embedUrl"
            :src="embedUrl"
            loading="lazy"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
        />
    </div>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';

const props = defineProps<BaseEmbedProps>();

const embedUrl = computed(() => {
    if (props.url.includes('vimeo.com/')) {
        // Todo, we need to check how the client is actually getting their vimeo urls.
        // return 'https://player.vimeo.com/video/' + props.url.split('vimeo.com/')[1];

        // For now extract the numbers from the url
        const id = props.url.match(/(\d+)/g);
        if (id?.length && id[0]) {
            return 'https://player.vimeo.com/video/' + id[0];
        }
    }
    return null;
});

useHead({
    script: [{
        src: 'https://player.vimeo.com/api/player.js',
    }]
});
</script>

<template>
    <transition name="fade">
        <div v-if="flippingbookId && url">
            <a
                :href="url"
                data-fbo-height="auto"
                class="fbo-embed"
                data-fbo-id="f6b3e0d7f4"
                data-fbo-ratio="3:2"
                data-fbo-lightbox="yes"
                data-fbo-width="100%"
                data-fbo-version="1"
                style="max-width: 100%;"
            >
                {{ description }}
            </a>
        </div>
        <content-placeholders
            v-else
            :rounded="true"
        >
            <content-placeholders-img style="height: 650px;" />
        </content-placeholders>
    </transition>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';

const props = defineProps<BaseEmbedProps>();

const flippingbookId = computed(() => {
    const regex = /(\d+)/g;
    const id = props.url.match(regex);

    // Since id is an array, we need to get the first match
    if (id?.length && id[0]) {
        useHead({
            script: [{
                src: `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=${id[0]}`,
                async: true,
                defer: true
            }]
        });

        return id[0];
    }

    return null;
});

</script>
